import React, {  useContext, useEffect, useState } from "react";
import { DataContext } from "contexts/DataContext";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


export const styles = {
  dialog: {  
    '& .MuiDialog-paper': {
      height: '90vh'
    }
  },
}

const ReportModal = ({ open = true, url, downloadUrl, onClose = () => null }) => {
  const { data } = useContext(DataContext);
  const [isOpen, setIsOpen] = useState(open);
  const theme = useTheme();
  const shouldBeFullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setIsOpen(open);
  }, [open])
    
  const handleClose = (_, reason) => {
    if (reason === "backdropClick") return;
    setIsOpen(false);
    onClose();
  };

  if (!open) return null;

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      fullWidth
      maxWidth="lg"
      fullScreen={shouldBeFullScreen}
      onBackdropClick={() => null}
      sx={ styles.dialog }
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Laudo
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <iframe style={{ height: '100%', width: '100%'}} src={url} title="laudo" />

      <DialogActions>
        <Button LinkComponent={"a"} href={downloadUrl || data.report.downloadUrl} download="laudo.pdf">
          Baixar laudo
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReportModal;