import React, { useEffect, useContext, useState, useRef } from 'react';
import './DashboardComplete.css';
import { DataContext } from "contexts/DataContext";
import { useNavigate, Navigate } from 'react-router-dom';
import { isMobile, isSafari } from 'react-device-detect';
import { Modal } from "antd"
import moment from "moment";
import axios from "axios";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import useMediaQuery from '@mui/material/useMediaQuery';

import ExitToAppIcon from "@mui/icons-material/ExitToApp";

import brand from '../contexts/brand';

import ReportModal from "components/ReportModal";
import ViewerModal from "components/ViewerModal";

import { classes } from './styles/DashboardStyle';

import logo from 'images/human_logo.png';
import humanDr from 'images/human_dr.png';
import vetDr from 'images/vet_dr.png';
import humanLogoHeader from 'images/human_logo_header.png';
import vetLogoHeader from 'images/vet_logo_header.png';
import backgroundHuman from 'images/background_human.png';
import backgroundVet from 'images/background_vet.png';


const CHECK_DOWNLOAD_INTERVAL = 3000;
const CHECK_PRINT_INTERVAL = 3000;
const MAX_PRINT_INTERVAL = 15*60*1000; // 15 minutos máximo de espera


const DashboardComplete = () => {
  const isSmall = useMediaQuery('(max-width: 800px)');

  const navigate = useNavigate();

  const { dataList } = useContext(DataContext);

  const [laudoOpen, setLaudoOpen] = useState(false);
  const [viewerUrl, setViewerUrl] = useState(false);

  const [downloadStatus, setDownloadStatus] = useState("none");
  const downloadZipUrl = useRef();

  const [printStatus, setPrintStatus] = useState("none");
  const printTotalTime = useRef();
  const printCanceled = useRef(false);
  const [printBuffer, setPrintBuffer] = useState();

  const hasData = dataList.length > 0;

  const accountId = dataList?.[0]?.study?.accountId;
  const canDownloadImages = dataList?.[0]?.downloadImagesLink;
  const canDownloadDicom = dataList?.[0]?.downloadStudyLink;

  async function handleDownloadStudy(studyIdx) {
    try {
      setDownloadStatus("zipping");
      const { shortKey, downloadUrl } = await axios
        .get(dataList[studyIdx].downloadStudyLink)
        .then((res) => res.data);
      downloadZipUrl.current = downloadUrl;

      let id = setInterval(checkDownload, CHECK_DOWNLOAD_INTERVAL);

      async function checkDownload() {
        const result = await axios
          .get(`${dataList[studyIdx].checkDownloadLink}&shortKey=${encodeURIComponent(shortKey)}`)
          .then((res) => res.data)
          .catch(e => {
            clearInterval(id);
            setDownloadStatus(e.toString());
          });
        if (result === true) {
          clearInterval(id);
          setDownloadStatus("done");
        }
      }
    } catch(e) {
      console.log('Error downloading zip: ', e.toString());
      setDownloadStatus(e.toString());
    }
  }

  async function handleDownloadIamges(studyIdx) {
    try {
      setDownloadStatus("zipping");
      const { shortKey, downloadUrl } = await axios
        .get(dataList[studyIdx].downloadImagesLink)
        .then((res) => res.data);
      downloadZipUrl.current = downloadUrl;

      let id = setInterval(checkDownload, CHECK_DOWNLOAD_INTERVAL);

      async function checkDownload() {
        const result = await axios
          .get(`${dataList[studyIdx].checkDownloadLink}&shortKey=${encodeURIComponent(shortKey)}`)
          .then((res) => res.data)
          .catch(e => {
            clearInterval(id);
            setDownloadStatus(e.toString());
          });
        if (result === true) {
          clearInterval(id);
          setDownloadStatus("done");
        }
      }
    } catch(e) {
      console.log('Error downloading zip: ', e.toString());
      setDownloadStatus(e.toString());
    }
  }

  const doDownloadStudy = () => {
    let link = document.createElement("a");
    link.href = downloadZipUrl.current;
    link.dispatchEvent(new MouseEvent("click"));
  };

  const [urlLaudo, setUrlLaudo] = useState();
  const [loadingLaudo, setLoadingLaudo] = useState(false);
  const [downloadLaudoUrl, setDownloadLaudoUrl] = useState();

  const handleOpen = async (studyIdx) => {
    setLoadingLaudo(true);

    const pdfUrl = dataList[studyIdx].report.downloadUrl;
    console.log('PDF URL ', pdfUrl)

    const pdfBody = await axios
      .get(pdfUrl, { responseType: 'blob' })
      .then(res => res.data);
    const file = new Blob([pdfBody], { type: 'application/pdf' });

    axios.post('/external/insertResultsPortalLogs', {
      type: 'openReport',
      studyId: dataList[studyIdx]?.study?.id,
      patientName: dataList[studyIdx]?.study?.patientName,
      accountId
    }).then(() => {}).catch((err) => { console.error(err); });
    
    if(isMobile) {
      if(isSafari) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          const element = document.createElement('a');
          element.href = window.URL.createObjectURL(file);
          element.download = "laudo.pdf";
          element.click();

          setLaudoOpen(false);
          setLoadingLaudo(false);
        };
      } else {
        window.open(window.URL.createObjectURL(file), "_blank");
        setLaudoOpen(false);
        setLoadingLaudo(false);
      }
    } else {
      setUrlLaudo(URL.createObjectURL(file));
      setDownloadLaudoUrl(pdfUrl);
      setLaudoOpen(true);
      setLoadingLaudo(false);
    }
  };

  useEffect(() => console.log(downloadLaudoUrl), [downloadLaudoUrl]);

  useEffect(() => {
    if (printCanceled.current === false && printBuffer) {
      // Abrindo o PDF
      try {
        const file = new Blob([printBuffer], { type: "application/pdf" });
        const printWindow = window.open(
          window.URL.createObjectURL(file),
          "_blank"
        );
        if (!printWindow) {
          throw new Error("Verifique o bloqueador de pop-ups do seu navegador");
        }
      } catch (e) {
        window.alert(e.message);
      }
    };
    setPrintBuffer(null);
    setPrintStatus('none');
  }, [printBuffer]);

  async function handlePrintStudy(studyIdx) {
    try {
      setPrintStatus("printing");
      printCanceled.current = false;
      const { downloadUrl } = await axios
        .get(dataList[studyIdx].printStudyLink)
        .then((res) => res.data)
        .catch(e => setPrintStatus(e.toString()));
      const printUrl = decodeURI(downloadUrl);
      printTotalTime.current = 0;

      setTimeout(checkPrint, CHECK_PRINT_INTERVAL);

      // Vou ficar esperando até o PDF ser criado no S3
      async function checkPrint() {
        const result = await axios
          .get(printUrl, { responseType: 'arraybuffer' })
          .then(res => new Blob([new Uint8Array(res.data)]))
          .catch(e => {
            printTotalTime.current += CHECK_PRINT_INTERVAL;
            // Se deu 404 continuo esperando, a menos que já ultrapassou o tempo máximo de espera
            if (e?.response?.status !== 404 || printTotalTime.current > MAX_PRINT_INTERVAL) {
              setPrintStatus(e.toString());
              printCanceled.current = true;
            } else {
              if (printCanceled.current === false) {
                setTimeout(checkPrint, CHECK_PRINT_INTERVAL);
              }
            }
          });
        if (!!result && printCanceled.current === false) {
          setPrintBuffer(result);

          axios.post('/external/insertResultsPortalLogs', {
            type: 'printStudy',
            studyId: dataList[studyIdx]?.study?.id,
            patientName: dataList[studyIdx]?.study?.patientName,
            accountId
          }).then(() => {}).catch((err) => { console.error(err); });
        }
      }
    } catch(e) {
      console.log('Error printing zip: ', e.toString());
      setPrintStatus(e.toString());
    }
  }

  if (!hasData) return <Navigate to="/" />

  return (
    <>
      <AppBar color="primary" position="static" sx={classes.appBar}>
        <img src={brand === 'vet' ? vetLogoHeader : humanLogoHeader} alt={brand === 'vet' ? 'Dr. Nuvem' : 'Dr. Tis'} style={{maxHeight: 30}} />

        <Button
          endIcon={<ExitToAppIcon />}
          onClick={() => navigate("/v2")}
          variant="contained"
          color="primary"
          sx={{ml: "auto"}}
        >
          Sair
        </Button>
      </AppBar>

      <div
        style={{
          height: '100vh',
          backgroundImage: !isSmall && (brand === 'vet' ? `url(${backgroundVet}`: `url(${backgroundHuman})`),
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center center'
        }}
      >
        <Container sx={classes.container}>
          <Modal
            maskClosable={false}
            title="Baixar estudo"
            visible={downloadStatus !== "none"}
            okButtonProps={{
              disabled: downloadStatus !== "done",
              loading: downloadStatus === "zipping",
            }}
            onOk={() => doDownloadStudy()}
            okText="Baixar"
            onCancel={() => {
              setDownloadStatus("none");
            }}
          >
            {downloadStatus === "zipping" && "Gerando o zip das imagens"}
            {downloadStatus === "done" &&
              'Pronto! Clique em "Baixar" para fazer o download'}
            {downloadStatus.startsWith('Erro') && downloadStatus}
          </Modal>

          <Modal
            maskClosable={false}
            title="Imprimir estudo"
            visible={printStatus !== "none"}
            okButtonProps={{
              disabled: printStatus !== "done",
              loading: printStatus === "printing",
            }}
            onCancel={() => {
              setPrintStatus("none");
              printCanceled.current = true;
            }}
          >
            {printStatus === "printing" && "Imprimindo o estudo"}
            {printStatus.startsWith('Erro') && printStatus}
          </Modal>

          <Paper sx={classes.paper}>
            <Grid sx={classes.infoInstitution}>
              {dataList[0].study?.accountLogo ? (
                <Box
                  component='img'
                  src={dataList[0].study?.accountLogo}
                  alt={dataList[0].study.accountName || 'Dr. TIS'}
                  sx={classes.logo}
                />
              ) : (
                <Box component='img' src={brand === 'vet' ? vetDr : humanDr} alt={brand === 'vet' ? 'Dr. Nuvem' : 'Dr. Tis'} sx={classes.logo} />
              )}
              {dataList[0].study?.accountName && (
                <Grid item>
                  <Typography variant="h3" as="span" display="block">
                    {dataList[0].study.accountName}
                  </Typography>
                </Grid>
              )}
            </Grid>

            <Grid container sx={classes.informations}>
              {(dataList[0]?.study?.socialName || dataList[0]?.study?.patientName) && (
                <Grid item>
                  <Typography variant="h4">Nome do paciente</Typography>
                  <Typography variant="h3">
                    {dataList[0].study.socialName || dataList[0].study.patientName}
                  </Typography>
                </Grid>
              )}
              {dataList[0]?.study?.patientSex && (
                <Grid item>
                  <Typography variant="h4">Sexo</Typography>
                  <Typography variant="h3">
                    {dataList[0]?.study?.patientSex}
                  </Typography>
                </Grid>
              )}
              {dataList[0]?.study?.patientAge && (
                <Grid item>
                  <Typography variant="h4">Idade</Typography>
                  <Typography variant="h3">
                    {dataList[0]?.study?.patientAge}
                  </Typography>
                </Grid>
              )}

              {dataList[0]?.study?.patientMother && (
                <Grid item>
                  <Typography variant="h4">Nome da mãe</Typography>
                  <Typography variant="h3">
                    {dataList[0]?.study?.patientMother}
                  </Typography>
                </Grid>
              )}
              {dataList[0]?.study?.patientResponsiblePerson && (
                <Grid item>
                  <Typography variant="h4">Responsável</Typography>
                  <Typography variant="h3">
                    {dataList[0]?.study?.patientResponsiblePerson}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Paper>

          <Paper sx={classes.paper}>
              <table className='responsive-table'>
                <thead>
                  <tr>
                    <th>Data exame</th>
                    <th>Exame</th>
                    <th>Modalidade</th>
                    <th>Instituição</th>
                    <th>Abrir laudo</th>
                    <th>Ver imagens</th>
                    <th>Imprimir imagens</th>
                    {canDownloadImages && (
                      <th>Baixar imagens</th>
                    )}
                    {canDownloadDicom && (
                      <th>Baixar DICOM</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {dataList.map((data, studyIdx) => (
                    <tr key={data.study.id}>
                      <td>
                        <span className="cell-header">Data exame:</span>
                        {moment(data.study.dicomCreatedAt).format('DD/MM/YYYY HH:mm:ss')}
                      </td>
                      <td>
                        <span className="cell-header">Exame:</span>
                        {data.study.examType}
                      </td>
                      <td>
                        <span className="cell-header">Modalidade:</span>
                        {data.study.modalities[0]}
                      </td>

                      <td>
                        <span className="cell-header">Instituição:</span>
                        {data.study.institutionName}
                      </td>
                      <td>
                        <span className="cell-header">Abrir laudo:</span>
                        <Button
                          variant="outlined"
                          onClick={() => handleOpen(studyIdx)}
                          disabled={!data?.report?.downloadUrl}
                        >
                          Abrir laudo
                          {loadingLaudo && (
                            <CircularProgress
                              size={20}
                              style={{ marginLeft: 25, color: 'white' }}
                            />
                          )}
                        </Button>
                      </td>
                      <td>
                        <span className="cell-header">Ver imagens:</span>
                        <Button
                          variant="outlined"
                          onClick={() => setViewerUrl(data.viewerLink)}
                          disabled={data.study.storageClass === 'GLACIER' || data.study.storageClass === 'DEEP_ARCHIVE'}
                        >
                          Ver imagens
                        </Button>
                      </td>
                      <td>
                        <span className="cell-header">Imprimir imagens:</span>
                        <Button
                          variant="outlined"
                          onClick={() => handlePrintStudy(studyIdx)}
                          disabled={!data?.printImagesLink
                            || (data.study.storageClass === 'GLACIER' || data.study.storageClass === 'DEEP_ARCHIVE')}
                        >
                          Imprimir imagens
                        </Button>
                      </td>
                      {canDownloadImages && (
                        <td>
                          <span className="cell-header">Baixar imagens:</span>
                          <Button
                            variant='outlined'
                            onClick={() => handleDownloadIamges(studyIdx)}
                            disabled={!data?.downloadImagesLink
                              || (data.study.storageClass === 'GLACIER' || data.study.storageClass === 'DEEP_ARCHIVE')}
                          >
                            Baixar imagens
                          </Button>
                        </td>
                      )}
                      {canDownloadDicom && (
                        <td>
                          <span className="cell-header">Baixar DICOM:</span>
                          <Button
                            variant='outlined'
                            onClick={() => handleDownloadStudy(studyIdx)}
                            disabled={!data?.downloadStudyLink
                              || (data.study.storageClass === 'GLACIER' || data.study.storageClass === 'DEEP_ARCHIVE')}
                          >
                            Baixar DICOM
                          </Button>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
          </Paper>
          {brand === 'human' && isSmall &&
            <Box sx={{textAlign: "center", marginTop: '7.2vw'}}>
              <a href="https://drtis.com.br">
                <img src={logo} alt={'Dr. Tis'} height="42" />
              </a>
            </Box>
          }

        </Container>
      </div>
      { !isMobile && <ReportModal open={laudoOpen} url={urlLaudo} downloadUrl={downloadLaudoUrl} onClose={() => setLaudoOpen(false)} /> }
      <ViewerModal open={!!viewerUrl} viewerUrl={viewerUrl} onClose={() => setViewerUrl(undefined)} />
    </>
  );
};

export default DashboardComplete;