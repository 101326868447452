import retangulo from 'images/retangulo.png';

export const loginStyle = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    alignSelf: 'end',
    width: { xs: '100%', md: '52vw', lg: '668px'},
    height: '100%',
    padding: { xs: '8vh 8.5vw 16.5vh', sm: '12vh 9vw 20.5vh' },
    background: { xs: 'white', md: `url(${retangulo})`},
    backgroundRepeat: 'round',
    textAlign: 'center',
    zIndex: 10
  },
  logo: {
    maxWidth: '90%',
    maxHeight: { xs: '8vh', sm: '9.9vh'},
    height: 'auto'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minWidth: '100%',
    height: '100%',
    justifyContent: 'space-between',
    margin: '2.3vh 0 10px',
  },
  input: {    
    '& .MuiFormLabel-root': {
      color: '#130B1F',
      fontFamily: 'Open Sans',
      fontWeight: '600',
      fontSize: '2.1vh',
    },
    '& .MuiInputBase-input': {
      color: (theme) => theme.palette.border.input,
      fontFamily: 'Open Sans',
      fontWeight: '600',
      fontSize: '2.1vh',
      minHeight: 45,
      paddingLeft: 1,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: (theme) => `1px solid ${theme.palette.border.input}`,   
      borderRadius: '8px',      
    },
  },
  button: {
    padding: '15px'
  },
  completeButton: {
    padding: '15px',
    minHeight: 40,
  }
}