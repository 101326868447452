import React, { useState, useEffect, useCallback, useContext, useRef } from "react";
import { DataContext } from "contexts/DataContext";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useNavigate, useParams } from 'react-router-dom';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import axios from 'axios';
import moment from 'moment';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from '@mui/material/CircularProgress';
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Typography from '@mui/material/Typography';

import brand from '../contexts/brand';

import { loginStyle } from './styles/LoginStyle';

import humanDr from 'images/human_dr.png';
import vetDr from 'images/vet_dr.png';
import humanLogo from 'images/human_logo.png';
import vetLogo from 'images/vet_logo.png';


const Login = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [username, setUserName] = useState(params?.studyId ?? '');
  const [password, setPassword] = useState('');
  const [patientBirthDate, setPatientBirthDate] = useState(null);

  const [reCaptchaToken, setRecaptchaToken] = useState();
  const maxDate = useRef(moment().endOf('day'));
  
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { setDataList } = useContext(DataContext);

  const urlParams = new URLSearchParams(window.location.search);
  const patientBirthDateNeeded = (urlParams.get('birthdateneeded') || '') === 'true';

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      setRecaptchaToken(undefined);
      console.error('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha("login_page");
    setRecaptchaToken(token);
    return token;
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const submit = async (submitEvent) => {
    submitEvent.preventDefault();

    setLoading(true);
    console.log("Checking captcha...");
    let token = reCaptchaToken
    if (!token) {
      try {
        token = await handleReCaptchaVerify();
      } catch(err) {
        window.alert("Erro ao verificar captcha. Tente novamente.");
      }
    } 

    const payload = {
      username,
      password,
      patientBirthDate: moment.utc(patientBirthDate).format('YYYYMMDD'),
      "g-captcha-response": reCaptchaToken,
    };
    axios.post("/external/results", payload).then(
      (response) => {
        if (response.data.length > 0) {
          setDataList(response.data);
          navigate("/v2/dashboard");
        } else {
          window.alert("Não foram encontrados resultados para esse paciente, por favor revise os dados de acesso");
          setLoading(false);
        }
      },
      (error) => {
        handleReCaptchaVerify();
        window.alert(error?.response?.data?.error?.description);
        setLoading(false);
      }
    );
  };

  if (!reCaptchaToken || loading) return (
    <Container
      maxWidth="sm"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%"
      }}
    >
      <CircularProgress sx={{mb: 3}} />
    </Container>
  );

  return (
    <>      
      <Box style={{     
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)'
      }}>
        <Box component='img' src={brand === 'vet' ? vetDr : humanDr} sx={{width: '48vw'}}/>
      </Box> 
      
      <Box sx={loginStyle.container}>
        <a href="https://drtis.com.br">
          <Box component='img' src={brand === 'vet' ? vetLogo : humanLogo} alt={brand === 'vet' ? 'Dr. Nuvem' : 'Dr. Tis'} sx={loginStyle.logo} />
        </a>
        <Box component='form' onSubmit={submit} sx={loginStyle.form}>
          <Typography variant="h1"> Portal de Resultados </Typography>
          <Typography variant="h5">
            Consulte imagens ou laudos dos exames usando 
            os dados fornecido pelo laboratório, clínica ou hospital:
          </Typography>
          <TextField
            fullWidth
            sx={loginStyle.input}
            onChange={(e) => setUserName(e.target.value)}
            defaultValue={username}
            name="username"
            label="Usuário"
          />
          <TextField
            fullWidth
            sx={loginStyle.input}
            onChange={(e) => setPassword(e.target.value)}
            defaultValue={password}
            name="password"
            label="Senha"
          />
          {patientBirthDateNeeded && (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="outlined"
                format="dd/MM/yyyy"
                margin="normal"
                label="Data de nascimento"
                value={patientBirthDate}
                onChange={(value) => setPatientBirthDate(value)}
                maxDate={maxDate.current}
                KeyboardButtonProps={{ 'aria-label': 'change date' }}
              />
            </MuiPickersUtilsProvider>
          )}
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            sx={loginStyle.completeButton}
            disabled={
              !username || !password || (patientBirthDate &&
              patientBirthDate.toString().includes('Invalid'))
            }
          >
            Acessar resultado
          </Button>
        </Box>
        <a href="https://drtis.com.br/pacs/">
          <Typography variant='h6'> Quer saber mais sobre PACS? </Typography>        
        </a> 
      </Box>        
    </>
  );
};

export default Login;