import React, { useState, useEffect, useCallback, useContext } from "react";
import { DataContext } from "contexts/DataContext";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useNavigate, useParams } from 'react-router-dom';
import InputMask from 'react-input-mask';
import axios from 'axios';

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from '@mui/material/CircularProgress';
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Typography from '@mui/material/Typography';

import brand from '../contexts/brand';

import { loginStyle } from './styles/LoginStyle';

import humanDr from 'images/human_dr.png';
import vetDr from 'images/vet_dr.png';
import humanLogo from 'images/human_logo.png';
import vetLogo from 'images/vet_logo.png';


const Login = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState(params?.studyId ?? "");
  const [reCaptchaToken, setRecaptchaToken] = useState();

  const [cpfVerification, setCpfVerification] = useState(false);
  const [cpf, setCpf] = useState('');

  const { executeRecaptcha } = useGoogleReCaptcha();
  const { setData } = useContext(DataContext);

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      setRecaptchaToken(undefined);
      console.error('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha("login_page");
    setRecaptchaToken(token);
    return token;
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const submit = async (submitEvent) => {
    submitEvent.preventDefault();

    setLoading(true);
    console.log("Checking captcha...");
    let token = reCaptchaToken
    if (!token) {
      try {
        token = await handleReCaptchaVerify();
      } catch(err) {
        window.alert("Erro ao verificar captcha. Tente novamente.");
      }
    } 

    const payload = {
      resultsKey: code.toUpperCase(),
      "g-captcha-response": reCaptchaToken,
    };
    axios.post("/external/results", payload).then(
      (response) => {
        setData(response.data);

        if(response.data.study.hasCpf) {
          setCpfVerification(true);
          setLoading(false);
        } else {
          navigate("/dashboard");
        }
      },
      (error) => {
        handleReCaptchaVerify();
        window.alert(error?.response?.data?.error?.description);
        setLoading(false);
      }
    );
  };

  const handleCpfVerification = async (event) => {
    event.preventDefault();

    setLoading(true);
    axios.post('/external/cpfVerification', { cpf, resultsKey: code.toUpperCase() })
      .then(() => {
        navigate('/dashboard');
      },
      (error) => {
        window.alert(error?.response?.data?.error?.description);
        setLoading(false);
      }
    );

  }

  if (!reCaptchaToken || loading) return (
    <Container
      maxWidth="sm"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%"
      }}
    >
      <CircularProgress sx={{mb: 3}} />
    </Container>
  );

  return (
    <>      
      <Box style={{     
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)'
      }}>
        <Box component='img' src={brand === 'vet' ? vetDr : humanDr} sx={{width: '48vw'}}/>
      </Box> 
      
      <Box sx={loginStyle.container}>
        <a href="https://drtis.com.br">
          <Box component='img' src={brand === 'vet' ? vetLogo : humanLogo} alt={brand === 'vet' ? 'Dr. Nuvem' : 'Dr. Tis'} sx={loginStyle.logo} />
        </a>    

        {cpfVerification ? (
          <Box component='form' onSubmit={handleCpfVerification} sx={loginStyle.form} style={{ margin: '11vh 0 1rem' }}>
            <Typography variant="h1"> Verificação ativa </Typography>

            <Typography variant="h5">
              Digite o CPF do paciente
            </Typography>
              <InputMask
              mask='999.999.999-99'
              maskChar='_'
              name='cpf'
              value={cpf}
              onChange={(e) => setCpf(e.target.value)}
              defaultValue={cpf}
            >
              {() => (
                <TextField
                  id='cpf'
                  label='CPF do paciente'
                  sx={loginStyle.input}
                  fullWidth
                />
              )}
            </InputMask>
            <Button variant="contained" color="primary" type="submit" fullWidth sx={loginStyle.button}>
              Confirmar
            </Button>
          </Box>
        ) : (
          <Box component='form' onSubmit={submit} sx={loginStyle.form}>
            <Typography variant="h1"> Portal de Resultados </Typography>

            <Typography variant="h5">
              Consulte imagens ou laudos dos exames usando 
              o código fornecido pelo laboratório, clínica ou hospital:
            </Typography>
            
            <TextField
              fullWidth
              sx={loginStyle.input}
              onChange={(e) => setCode(e.target.value)}
              defaultValue={code}
              name="code"
              label="Digite o código de acesso:"
            />
            <Button variant="contained" color="primary" type="submit" fullWidth sx={loginStyle.button}>
              Acessar resultado
            </Button>
          </Box>
        )}

        <a href="https://drtis.com.br/pacs/">
          <Typography variant='h6'> Quer saber mais sobre PACS? </Typography>        
        </a> 
      </Box>        
    </>
  );
};

export default Login;